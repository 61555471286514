<script>
import { useRecProfile } from '/~rec/composables/profile'
import { formatNumber } from '/~/utils/format/numeric'
import { pluralize } from '/~/utils/format/string'
import { useAward } from '/~rec/composables/award'
import AwardLeaderboard from '/~rec/components/award/award-leaderboard.vue'
import AwardNominations from '/~rec/components/award/award-nominations.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import DetailsMobile from '/~rec/components/recognition/recognition-details-box-mobile.vue'
import modal from '/~/core/mdl'
import ui from '/~/core/ui'

export default {
  name: 'rec-award-details-mobile',
  components: {
    BaseMdl,
    BaseIcon,
    BaseButton,
    DetailsMobile,
    AwardNominations,
    AwardLeaderboard,
  },
  props: {
    awardStatus: {
      type: String,
      default: '',
    },
    awardId: {
      type: String,
      default: '',
    },
    selectedTab: {
      type: String,
      default: 'information',
    },
    winnerId: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { currency } = useRecProfile()

    const {
      award,
      getNominal,
      init,
      loading,
      nominate,
      nominateIsDisabled,
      onRowClick,
      rank,
      status,
    } = useAward()

    return {
      award,
      currency,
      getNominal,
      init,
      loading,
      nominate,
      nominateIsDisabled,
      onRowClick,
      rank,
      status,
      ui,
    }
  },
  created() {
    this.init()
  },
  methods: {
    pluralize,
    formatNumber,
    close() {
      modal.hide()
      this.$router.replace({
        name: 'rec-awards',
        query: { type: this.awardStatus },
      })
    },
  },
}
</script>

<template>
  <base-mdl fullscreen :layout="false" width="screen" transition="slide-bottom">
    <details-mobile
      :loading="loading"
      :award="award || {}"
      entity-name="rec-awards"
      alt-text="award"
      title-message="Details"
      @close="close"
    >
      <template #badge>
        <div class="flex items-center">
          <div class="mr-6">
            <div v-if="status">
              <span
                :class="[
                  'mb-5 inline-block rounded-3xl px-5 py-[5px] text-sm font-semibold capitalize',
                  status.theme,
                  status.textColor,
                ]"
              >
                {{ status.label }}
              </span>
            </div>
          </div>

          <div v-if="rank">
            <span
              :class="[
                'mb-5 inline-flex items-center rounded-3xl px-5 py-[5px] text-sm font-semibold capitalize text-white',
                rank.bg,
              ]"
            >
              <base-icon
                svg="symbion/award"
                size="xxs"
                class="mr-2.5 shrink-0"
              />
              {{ rank.label }}
            </span>
          </div>
        </div>
      </template>
      <template #nominalText>
        <span class="text-sm font-bold leading-none">
          Most nominations win&nbsp;
          {{ formatNumber(getNominal) }}
          <span class="capitalize">
            {{ pluralize(getNominal, currency.slice(0, -1)) }}
          </span>
        </span>
      </template>
      <template v-if="!ui.desktop && award" #nominations="{ isOpen }">
        <award-nominations
          :status="awardStatus"
          :nomination-limit-label="award.nominateLimitLabel"
          :ends-at="award.endsAt"
          :current-period="award.currentPeriod.endsAt"
          :class="{
            'text-eonx-neutral-600': isOpen,
            'text-zinc-100': !isOpen,
          }"
        />
      </template>
      <template #actions>
        <div class="my-6 ml-auto flex w-full justify-center lg:block">
          <base-button
            :loading="loading"
            :disabled="nominateIsDisabled"
            class="w-full sm:w-48"
            @click="nominate()"
          >
            <span class="text-base">Nominate</span>
          </base-button>
        </div>
      </template>
      <template #footer>
        <div class="flex flex-col space-y-[15px] pb-6">
          <h3 class="mb-2.5">Leaderboard</h3>
          <award-leaderboard
            :award="award"
            :nominate-is-disabled="nominateIsDisabled"
            @row-click="onRowClick"
            @nominate="nominate()"
          />
        </div>
      </template>
    </details-mobile>
  </base-mdl>
</template>
